import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
// import {FormattedMessage} from "react-intl";
import { LOCALES } from "../../i18n/locales";
import { LangContext } from "../../App";
import { SignOutButton } from "../SignOutButton";
import { FormattedMessage } from "react-intl";
import { useLocation } from 'react-router-dom'

function NavBar() {

    const languages = [
        { name: 'English', code: LOCALES.ENGLISH },
        { name: 'Русский', code: LOCALES.RUSSIAN },
        { name: 'Deutsch', code: LOCALES.GERMAN }
    ];
    const { locale, setLocale } = useContext(LangContext);

    const handleChangeLocale = ({ target: { value } }) => {
        setLocale(value);
        localStorage.setItem('locale', value);
    };

    const location = useLocation();

    return (
        <nav className="py-3 navbar navbar-dark bg-primary navbar-expand-lg auto-hiding-navbar">
            <div className="container">
                <NavLink className="navbar-brand" to="/">Laer Gruppe</NavLink>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-links">
                    <li className="nav-item">
                        <Link className={"nav-link " + (location.pathname === "/customers" ? "active" : undefined)} aria-current="page" to="/customers">
                            <FormattedMessage id="customers" />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={"nav-link " + (location.pathname === "/users" ? "active" : "")} aria-current="page" to="/users">
                            <FormattedMessage id="users" />
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={"nav-link " + (location.pathname === "/metrics" ? "active" : "")} aria-current="page" to="/metrics">
                            Metrics
                        </Link>
                    </li>
                </ul>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <ul className="nav navbar-nav navbar-right">
                    <div className="mx-2">
                        <select value={locale} onChange={handleChangeLocale} className="form-control">
                            {languages.map(({ name, code }) => (
                                <option key={code} value={code}>{name}</option>
                            ))}
                        </select>
                    </div>
                    <SignOutButton />
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;