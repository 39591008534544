import React, {useEffect, useState} from "react";
import {getLocks} from "../backend";
import {FormattedMessage, useIntl} from "react-intl";
import Select from "react-select";
import {PrimaryButton} from "./Buttons";

function LockSearchSelect({onClick}) {
    const intl = useIntl();
    // React state to manage selected options
    const [selectedOptions, setSelectedOptions] = useState(null);

    // Array of all options
    const [optionsList, setOptionsList] = useState([]);

    // Function triggered on selection
    const handleSelect = (data) => {
        setSelectedOptions(data);
    }

    useEffect(() => {
        getLocks()
            .then(data => setOptionsList(data.map(el => { return {value: el.id, label: el.name}; })));
    }, []);


    return (
        <div className="input-group-project-st mt-20">
            <div className="form-outline" style={{flex: 'auto'}}>
                <div className="input-group flex-nowrap">
                    <span className="input-group-text"><i className="fa fa-search color-sky-grey"></i></span>
                    <Select
                        defaultValue={selectedOptions}
                        options={optionsList}
                        placeholder={intl.formatMessage({id: 'search_placeholder'})}
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        className="br-l-none w-100"
                        styles={{width: 'full'}}
                        isMulti
                    />
                    {/*<input type="text" name="search" id="search" placeholder={intl.formatMessage({id: 'search_placeholder'})} className="form-control br-l-none" value={search} onChange={(e) => setSearch(e.target.value)} />*/}
                </div>
            </div>
            <PrimaryButton onClick={() => { onClick(selectedOptions.map(el => el.value)); }}>
                <i className="fa fa-plus"></i> <FormattedMessage id="add_button"/>
            </PrimaryButton>
        </div>
    );
}

export default LockSearchSelect;