
class CreateUser {
    id;
    /** @type {string|null} */
    firstName;
    /** @type {string|null} */
    lastName;
    /** @type {string|null} */
    email;
    /** @type {string|null} */
    postalCode;
    /** @type {string|null} */
    address;
    /** @type {string|null} */
    city;
    /** @type {string|null} */
    mobile;
    /** @type {string|null} */
    phone;
    /** @type {string|null} */
    accessCode;
    parentUserId;
    lockIds;

    constructor(data: Object | null = null) {
        if (data !== undefined && data !== null) {
            this.id = data?.userId || "";
            this.email = data?.userEmail;
            this.firstName = data?.userFirstName;
            this.lastName = data?.userLastName;
            this.postalCode = data?.postalCode;
            this.address = data?.address;
            this.city = data?.city;
            this.mobile = data?.mobile;
            this.phone = data?.phone;
            this.accessCode = data?.accessCode;
            this.parentUserId = data?.parentUserId;
            this.lockIds = data?.lockIds;
        }

        else {
            this.id = '';
            this.email = null;
            this.firstName = null;
            this.lastName = null;
            this.postalCode = null;
            this.address = null;
            this.city = null;
            this.mobile = null;
            this.phone = null;
            this.accessCode = null;
            this.parentUserId = null;
            this.lockIds = [];
        }
    }

    toJson() {
        const userData = {
            UserId: this.id || "",
            Email: this.email || "",
            FirstName: this.firstName || "",
            LastName: this.lastName || "",
            Address: this.postalCode || "",
            PostalCode: this.address || "",
            City: this.city || "",
            Mobile: this.mobile || "",
            Phone: this.phone || "",
            AccessCode: this.accessCode || "",
            ParentUserId: this.parentUserId || "",
            LockIds: this.lockIds || ""
        };

        if (this.id.trim() !== '') { userData['UserId'] = this.id; }

        return userData;
    }

    isValid() {
        return this.email && this.lockIds && this.lockIds.length
    }
}

export default CreateUser;