
class User {
    /** @type {string} */
    id;
    /** @type {string|null} */
    email;
    /** @type {string|null} */
    firstName;
    /** @type {string|null} */
    lastName;
    /** @type {string|null} */
    passwordHash;
    /** @type {string|null} */
    clientId;
    mainUserId;
    locks;

    constructor(data: Object | null = null) {
        if (data !== undefined && data !== null) {
            this.id = data.userId;
            this.email = data?.userEmail;
            this.firstName = data?.userFirstName;
            this.lastName = data?.userLastName;
            this.passwordHash = data?.userPasswordHash;
            this.clientId = data?.clientId;
            this.mainUserId = data?.mainUserId;
            this.locks = data?.locks;
        }

        else {
            this.id = '';
            this.email = null;
            this.firstName = null;
            this.lastName = null;
            this.passwordHash = null;
            this.clientId = null;
            this.mainUserId = null;
            this.locks = [];
        }
    }

    toJson() {
        const userData = {
            UserEmail: this.email || '',
            UserFirstName: this.firstName || '',
            UserLastName: this.lastName || '',
            UserPasswordHash: this.passwordHash || '',
            ClientId: this.clientId || '',
            MainUserId: this.mainUserId || '',
            Locks: this.locks || []
        };

        if (this.id.trim() !== '') { userData['UserId'] = this.id; }

        return userData;
    }
}

export default User;