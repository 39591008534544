import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const Pagination = styled(ReactPaginate).attrs({activeClassName: 'active', breakLabel: '...'})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;
  li a,
  li.previous a,
  li.next a {
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
    border: 1px solid #dee2e6;
    cursor: pointer;
    text-decoration: none;
  }
  li.previous a,
  li.next a,
  li.break a {
    //border-color: transparent;
  }
  li a:hover {
    background: #e9ecef;
  }
  li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.active a:hover {
    color: #fff;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

export default Pagination;