import Status from "./Lock/Status";

class Lock {
    /** @type {string} */
    id;
    /** @type {string|null} */
    api;
    /** @type {string|null} */
    name;
    /** @type {string|null} */
    description;
    /** @type {Status} */
    status;

    constructor(data: Object|null = null) {
        if(data !== undefined && data !== null) {
            this.id = data.lockId;
            this.api = data?.lockApi;
            this.name = data?.lockName;
            this.description = data?.lockDescription;
            this.status = Status.fromApi(data.lockStatus);
        }

        else {
            this.id = '';
            this.api = null;
            this.name = null;
            this.description = null;
            this.status = Status.VACANT;
        }
    }

    toJson() {
        const lockData = {
            LockApi: this.api || '',
            LockName: this.name || '',
            LockDescription: this.description || '',
            LockStatus: this.status.name
        };

        if(this.id.trim() !== '') { lockData['LockId'] = this.id; }

        return lockData;
    }
}

export default Lock;