import React from 'react';
import { useEffect, useState } from "react";
import { AuthenticatedLayout } from "../../layouts/AuthenticatedLayout";
import Pagination from "../../components/Pagination";
import { DangerButton, LightButton, PrimaryButton } from "../../components/Buttons";
import {
    assignLockToUser,
    changeLockStatus,
    deleteUser,
    getUserLocks,
    getUsers,
    saveUser,
    saveChildUser
} from "../../backend";
import Modal from "../../components/Modal";
import { Buffer } from "buffer";
import MD5 from "crypto-js/md5";
import { FormattedMessage, useIntl } from "react-intl";
import Search from "../../components/Search";
import User from "../../backend/DTO/User";
import LockSearchSelect from "../../components/LockSearchSelect";
import Status from "../../backend/DTO/Lock/Status";
import CreateUser from "../../backend/DTO/CreateUser";
import { MultiSelect } from "react-multi-select-component";

function LockStatusChangeble({ item, onChanged }) {
    const intl = useIntl();
    const [formStatus, setFormStatus] = useState(item?.status);

    const handleChangeEvent = ({ target: { value } }) => {
        console.log(value);
        const newStatus = Status.fromApi(value);
        setFormStatus(newStatus);
        changeLockStatus(item, newStatus)
            .finally(() => { onChanged(newStatus); });
    }

    return (
        <td className="align-middle">
            <select id="InputStatus" className="form-control" placeholder={intl.formatMessage({ id: 'field_status' })} value={formStatus.name} onChange={handleChangeEvent}>
                {Status.list().map((statusOption: Status) => (
                    <option value={statusOption.name} key={statusOption.name}><FormattedMessage id={statusOption.localizationKey} /></option>
                ))}
            </select>
        </td>
    );
}

function List() {
    const [loading: boolean, setLoading] = useState(true);
    const [users: User[], setUsers] = useState([]);

    const itemsPerPage = 15;
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = users.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(users.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % users.length;
        setItemOffset(newOffset);
    };

    const handleSearchClick = (searchQuery) => {
        setLoading(true);
        getUsers(searchQuery)
            .then(response => {
                setItemOffset(0);
                setUsers(response);
            })
            .finally(() => { setLoading(false); });
    }

    useEffect(() => {
        setLoading(true);
        getUsers()
            .then((response) => setUsers(response))
            .finally(() => { setLoading(false); });
    }, []);

    /** Show modal */
    const [selectedForShowModal: User| null, setSelectedForShowModal] = useState(null);
    const [loadingUserLocks: boolean, setLoadingUserLocks] = useState(false);
    const [userLocks: Lock[], setUserLocks] = useState([]);

    const loadUserLocks = (user: User) => {
        setLoadingUserLocks(true);
        getUserLocks(user)
            .then(response => setUserLocks(response))
            .finally(() => setLoadingUserLocks(false));
    }
    const onClickShowUser = (user: User) => {
        setSelectedForShowModal(user);
        loadUserLocks(user);
    }

    const assignLocks = (lockIds: string[]) => {
        const setLocks = userLocks.map((lock: Lock) => lock.id);
        lockIds.forEach(lockId => { if (!(lockId in setLocks)) { setLocks.push(lockId); } })
        assignLockToUser(selectedForShowModal, setLocks)
            .then(() => loadUserLocks(selectedForShowModal));
    }

    const unsignLock = (lockId: string) => {
        const setLocks = userLocks.map((lock: Lock) => lock.id).filter(elem => elem.trim() !== lockId.trim());
        assignLockToUser(selectedForShowModal, setLocks)
            .then(() => loadUserLocks(selectedForShowModal));
    }

    const onCloseShowModal = () => { setSelectedForShowModal(null); setUserLocks([]); }

    /** Add/Edit Modal */
    const [selectedForAddEditModal: User| null, setSelectedForAddEditModal] = useState(null);
    const [formEmail, setFormEmail] = useState('');
    const [formPassword, setFormPassword] = useState('');
    const [formFirstName, setFormFirstName] = useState('');
    const [formLastName, setFormLastName] = useState('');
    const [formClientId, setFormClientId] = useState('');

    const fillForm = (user: User | null) => {
        setFormEmail(user?.email || '');
        setFormPassword('');
        setFormFirstName(user?.firstName || '');
        setFormLastName(user?.lastName || '');
        setFormClientId(user?.clientId || '');
    }

    const onClickAddUser = () => { let user = new User(); fillForm(user); setSelectedForAddEditModal(user); }
    const onClickEditUser = (user: User) => { fillForm(user); setSelectedForAddEditModal(user); }
    const onCloseAddEditModal = () => { setSelectedForAddEditModal(null); };

    const onSave = () => {
        if (selectedForAddEditModal !== null) {
            selectedForAddEditModal.email = formEmail;
            if ((formPassword || '') !== '') {
                selectedForAddEditModal.passwordHash = Buffer.from(MD5(formPassword).toString()).toString('base64');
            }
            selectedForAddEditModal.firstName = formFirstName;
            selectedForAddEditModal.lastName = formLastName;
            selectedForAddEditModal.clientId = formClientId;
            saveUser(selectedForAddEditModal)
                .then(userId => {
                    if (selectedForAddEditModal.id.trim() === '') {
                        selectedForAddEditModal.id = userId;
                        users.push(selectedForAddEditModal);
                        setUsers(users);
                    }
                    else {
                        users.splice(
                            users.findIndex(user => user.id === selectedForAddEditModal.id),
                            1,
                            selectedForAddEditModal
                        );
                        setUsers(users);
                    }
                    onCloseAddEditModal();
                })
                .catch(err => { console.log(err); });
        }
    }

    /** Delete Modal */
    const [selectedForDeleteModal: User| null, setSelectedForDeleteModal] = useState(null);
    const onClickDeleteUser = (user) => { setSelectedForDeleteModal(user); };
    const onCloseDeleteModal = () => { setSelectedForDeleteModal(null); };
    const onDelete = () => {
        if (selectedForDeleteModal !== null) {
            deleteUser(selectedForDeleteModal)
                .then(result => {
                    if (result) {
                        setUsers(users.filter(user => user.id !== selectedForDeleteModal.id));
                        onCloseDeleteModal();
                    }
                });
        }
    }

    const intl = useIntl();

    const createEmptyUser: CreateUser = () => new CreateUser();

    const [addUserModalRef, setAddUserModalRef] = useState(null);
    const [addUserEntity: CreateUser, setAddUserEntity] = useState(createEmptyUser());
    const [createUserLockIds, setCreateUserLockIds] = useState([]);
    const openAddUserModal = (user) => {
        setSelectedUser(user);
        setAddUserEntity(createEmptyUser());
        setAddUserModalRef(123);
    }

    const closeAddUserModal = () => {
        setAddUserEntity(createEmptyUser());
        setAddUserModalRef(null);
        setSelectedUser(null);
    }

    const addUserAction = () => {
        addUserEntity.parentUserId = selectedUser.id;
        saveChildUser(addUserEntity).then(() => { setAddUserModalRef(null); setSelectedUser(null); setCreateUserLockIds([]); })
    }

    const [selectedUser, setSelectedUser] = useState(null);

    return (
        <AuthenticatedLayout>
            <Search onSearch={handleSearchClick} onChange={() => { }}>
                <PrimaryButton onClick={onClickAddUser}><i className="fa fa-plus"></i> <FormattedMessage id="users_add_button" /></PrimaryButton>
            </Search>
            <div className="table-box mt-20">
                {loading ? (
                    <div className="align-content-center text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden"><FormattedMessage id="loading" /></span>
                        </div>
                    </div>
                ) : (
                    <>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td><FormattedMessage id="field_email" /></td>
                                    <td><FormattedMessage id="field_last_name" /></td>
                                    <td><FormattedMessage id="field_first_name" /></td>
                                    <td style={{ 'minWidth': '90px' }}><FormattedMessage id="field_client_id" /></td>
                                    <td className="text-end"><FormattedMessage id="field_actions" /></td>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className="align-middle" onClick={() => onClickShowUser(item)}>{item.email || ''}</td>
                                        <td className="align-middle" onClick={() => onClickShowUser(item)}>{item.lastName || ''}</td>
                                        <td className="align-middle" onClick={() => onClickShowUser(item)}>{item.firstName || ''}</td>
                                        <td className="align-middle" onClick={() => onClickShowUser(item)}>{item.clientId || ''}</td>
                                        <td className="text-end align-middle">
                                            {!item.mainUserId ?
                                                <><LightButton className="btn-sm" onClick={() => {
                                                    openAddUserModal(item);
                                                }}><i className="fa fa-users mx-2"></i></LightButton>
                                                </> : <></>}
                                            <LightButton className="btn-sm" onClick={(e) => { e.stopPropagation(); onClickEditUser(item) }}><i className="fa fa-pencil mx-2"></i></LightButton>
                                            <DangerButton className="btn-sm ml-10" onClick={(e) => { e.stopPropagation(); onClickDeleteUser(item) }}><i className="fa fa-trash mx-2"></i></DangerButton>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr><td colSpan={5} className="text-center"><FormattedMessage id={'no_results'} /></td></tr>
                                )}
                            </tbody>
                        </table>
                        <div className='pagination-box mt-40'>
                            <Pagination
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                renderOnZeroPageCount={null}
                                nextLabel={intl.formatMessage({ id: 'pagination_next' })}
                                previousLabel={intl.formatMessage({ id: 'pagination_prev' })}
                            />
                        </div>
                    </>
                )}
            </div>

            <Modal
                show={selectedForShowModal !== null}
                title={selectedForShowModal !== null ? intl.formatMessage({ id: 'user_show' }, { last_name: selectedForShowModal.lastName, first_name: selectedForShowModal.firstName }) : null}
                size="xl"
                onClose={onCloseShowModal}
            >
                {loadingUserLocks ? (
                    <div className="align-content-center text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden"><FormattedMessage id="loading" /></span>
                        </div>
                    </div>
                ) : (
                    <>
                        <LockSearchSelect onClick={assignLocks} />
                        <table className="table">
                            <thead>
                                <tr>
                                    <td><FormattedMessage id="field_api" /></td>
                                    <td><FormattedMessage id="field_name" /></td>
                                    <td><FormattedMessage id="field_description" /></td>
                                    <td><FormattedMessage id="field_status" /></td>
                                    <td className="text-end"><FormattedMessage id="field_actions" /></td>
                                </tr>
                            </thead>
                            <tbody>
                                {userLocks.length > 0 ? userLocks.map((item, index) => (
                                    <tr key={index}>
                                        <td className="align-middle">{item?.api || ''}</td>
                                        <td className="align-middle">{item?.name || ''}</td>
                                        <td className="align-middle">{item?.description || ''}</td>
                                        <LockStatusChangeble item={item} onChanged={() => loadUserLocks(selectedForShowModal)} />
                                        <td className="text-end align-middle">
                                            {/*<LightButton className="btn-sm" onClick={() => onClickEditLock(item)}><i className="fa fa-pencil mx-2"></i></LightButton>*/}
                                            <DangerButton className="btn-sm ml-10" onClick={() => unsignLock(item.id)}><i className="fa fa-unlink mx-2"></i></DangerButton>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr><td colSpan={5} className="text-center"><FormattedMessage id={'no_results'} /></td></tr>
                                )}
                            </tbody>
                        </table>
                    </>
                )}
            </Modal>

            <Modal
                show={selectedForAddEditModal !== null}
                title={selectedForAddEditModal !== null
                    ? (intl.formatMessage({ id: selectedForAddEditModal.id.trim() === '' ? 'user_add' : 'user_edit' }, { name: selectedForAddEditModal.id.trim() === '' ? '' : `${selectedForAddEditModal.lastName} ${selectedForAddEditModal.firstName}` }))
                    : null
                }
                onClose={onCloseAddEditModal}
                footer={(
                    <>
                        <LightButton onClick={onCloseAddEditModal}><FormattedMessage id="cancel_button" /></LightButton>
                        <PrimaryButton onClick={onSave}><FormattedMessage id="save_button" /></PrimaryButton>
                    </>
                )}
            >
                <div className="box-form">
                    <div className="mb-3">
                        <label htmlFor="InputEmail" className="form-label"><FormattedMessage id="field_email" /></label>
                        <input id="InputEmail" name="email" type="email" className="form-control" placeholder={intl.formatMessage({ id: 'field_email' })} value={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="InputPassword" className="form-label"><FormattedMessage id="field_password" /></label>
                        <input id="InputPassword" name="password" type="password" className="form-control" placeholder={intl.formatMessage({ id: 'field_password' })} value={formPassword} onChange={(e) => setFormPassword(e.target.value)} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="InputFirstName" className="form-label"><FormattedMessage id="field_first_name" /></label>
                        <input id="InputFirstName" name="firstName" type="text" className="form-control" placeholder={intl.formatMessage({ id: 'field_first_name' })} value={formFirstName} onChange={(e) => setFormFirstName(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="InputLastName" className="form-label"><FormattedMessage id="field_last_name" /></label>
                        <input id="InputLastName" name="lastName" type="text" className="form-control" placeholder={intl.formatMessage({ id: 'field_last_name' })} value={formLastName} onChange={(e) => setFormLastName(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="InputLastName" className="form-label"><FormattedMessage id="field_client_id" /></label>
                        <input id="InputClientID" name="clientId" type="text" className="form-control" placeholder={intl.formatMessage({ id: 'field_client_id' })} value={formClientId} onChange={(e) => setFormClientId(e.target.value)} />
                    </div>
                </div>
            </Modal>

            <Modal
                show={selectedForDeleteModal !== null}
                title={selectedForDeleteModal !== null ? intl.formatMessage({ id: 'user_delete' }) : ''}
                onClose={onCloseDeleteModal}
                footer={(
                    <>
                        <LightButton onClick={onCloseDeleteModal}><FormattedMessage id="cancel_button" /></LightButton>
                        <DangerButton onClick={onDelete}><FormattedMessage id="delete_button" /></DangerButton>
                    </>
                )}
            >
                <FormattedMessage id="user_delete_confirm_text" values={{ name: selectedForDeleteModal !== null ? `${selectedForDeleteModal.lastName} ${selectedForDeleteModal.firstName}` : '' }} />
            </Modal>
            <Modal
                show={addUserModalRef !== null}
                title={
                    intl.formatMessage({ id: "add_user_modal_title" })
                }
                size="xl"
                onClose={closeAddUserModal}
                footer={
                    <>
                        <LightButton onClick={closeAddUserModal}>
                            <FormattedMessage id="cancel_button" />
                        </LightButton>
                        <PrimaryButton
                            onClick={addUserAction}
                            disabled={!addUserEntity.isValid()}
                        >
                            <FormattedMessage id="save_button" />
                        </PrimaryButton>
                    </>
                }
            >
                <div id="create_user_form">
                    <div className="w-100 d-flex flex-row gap-2">
                        <div className="w-100">
                            <label htmlFor="InputFirstName" className="form-label">
                                <FormattedMessage id="field_first_name" />
                            </label>
                            <input
                                id="InputFirstName"
                                name="InputFirstName"
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "field_first_name" })}
                                defaultValue={addUserEntity?.firstName}
                                value={addUserEntity?.firstName}
                                onChange={(e) => {
                                    addUserEntity.firstName = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>

                        <div className="w-100">
                            <label htmlFor="InputLastName" className="form-label">
                                <FormattedMessage id="field_last_name" />
                            </label>
                            <input
                                id="InputLastName"
                                name="InputLastName"
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "field_last_name" })}
                                defaultValue={addUserEntity?.lastName}
                                value={addUserEntity?.lastName}
                                onChange={(e) => {
                                    addUserEntity.lastName = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <div className="w-100">
                            <label htmlFor="InputEmail" className="form-label">
                                <FormattedMessage id="field_email" />
                            </label>
                            <input
                                id="InputEmail"
                                name="InputEmail"
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "field_email" })}
                                defaultValue={addUserEntity?.email}
                                value={addUserEntity?.email}
                                onChange={(e) => {
                                    addUserEntity.email = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>

                        <div className="w-100">
                            <label htmlFor="InputPostalCode" className="form-label">
                                <FormattedMessage id="field_postal_code" />
                            </label>
                            <input
                                id="InputPostalCode"
                                name="InputPostalCode"
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "field_postal_code" })}
                                defaultValue={addUserEntity?.postalCode}
                                value={addUserEntity?.postalCode}
                                onChange={(e) => {
                                    addUserEntity.postalCode = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <div className="w-100">
                            <label htmlFor="InputAddress" className="form-label">
                                <FormattedMessage id="field_address" />
                            </label>
                            <input
                                id="InputAddress"
                                name="InputAddress"
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "field_address" })}
                                defaultValue={addUserEntity?.address}
                                value={addUserEntity?.address}
                                onChange={(e) => {
                                    addUserEntity.address = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>

                        <div className="w-100">
                            <label htmlFor="InputCity" className="form-label">
                                <FormattedMessage id="field_city" />
                            </label>
                            <input
                                id="InputCity"
                                name="InputCity"
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "field_city" })}
                                defaultValue={addUserEntity?.city}
                                value={addUserEntity?.city}
                                onChange={(e) => {
                                    addUserEntity.city = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <div className="w-100">
                            <label htmlFor="InputMobile" className="form-label">
                                <FormattedMessage id="field_mobile" />
                            </label>
                            <input
                                id="InputMobile"
                                name="InputMobile"
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "field_mobile" })}
                                defaultValue={addUserEntity?.mobile}
                                value={addUserEntity?.mobile}
                                onChange={(e) => {
                                    addUserEntity.mobile = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>

                        <div className="w-100">
                            <label htmlFor="InputCity" className="form-label">
                                <FormattedMessage id="field_phone" />
                            </label>
                            <input
                                id="InputPhone"
                                name="InputPhone"
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "field_phone" })}
                                defaultValue={addUserEntity?.phone}
                                value={addUserEntity?.phone}
                                onChange={(e) => {
                                    addUserEntity.phone = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row gap-2">
                        <div className="w-100">
                            <label htmlFor="InputAccessCode" className="form-label">
                                Access Code:
                            </label>
                            <input
                                id="InputAccessCode"
                                name="InputAccessCode"
                                type="text"
                                className="form-control"
                                placeholder="Access Code"
                                defaultValue={addUserEntity?.accessCode}
                                value={addUserEntity?.accessCode}
                                onChange={(e) => {
                                    addUserEntity.accessCode = e.target.value;
                                    setAddUserEntity(addUserEntity)
                                }
                                }
                            />
                        </div>
                        <div className="w-100">
                            {(selectedUser && selectedUser.locks && selectedUser.locks.length)
                                ? <>
                                    <label>
                                        <FormattedMessage id="locks" />
                                    </label>
                                    <div style={{ marginTop: 7 + "px" }}>
                                        <MultiSelect
                                            options={selectedUser.locks.map((lock) => {
                                                return { label: lock.lockName, value: lock.lockId };
                                            })}
                                            value={createUserLockIds}
                                            onChange={(e) => {
                                                addUserEntity.lockIds = e.map(v => v.value);
                                                setAddUserEntity(addUserEntity);
                                                setCreateUserLockIds(e);
                                            }}
                                            labelledBy="Select Locks"
                                        />
                                    </div>
                                </>
                                : <>none</>
                            }
                        </div>
                    </div>
                </div>
            </Modal>

        </AuthenticatedLayout>
    );
}

export { List };